import http from '@/utils/http';

export function installPaymentAppRh(params) {
  return http.get('/v1/plugin/appInstall/shoplazza/payment_app_rh', { params });
}

export function installPaymentAppLpms(params) {
  return http.get('/v1/plugin/appInstall/shoplazza/payment_app_lpms', { params });
}

// 根据后端路由，动态拼接对应的插件
export function installPaymentAppSplicing(websitePlatform, appName, params) {
  return http.get(`/v1/plugin/appInstall/${websitePlatform}/${appName}`, { params });
}

export function installPaymentAppActive(websitePlatform, appName, params) {
  return http.get(`/v1/plugin/active/${websitePlatform}/${appName}`, { params });
}

// 获取重定向安装地址
export function getRedirectUrl(websitePlatform, appName, params) {
  return http.get(`/v1/plugin/redirectUri/${websitePlatform}/${appName}`, { params });
}
