<template>
  <div class="app-install" v-loading.fullscreen="fullscreenLoading">
    <div class="title">Onerway Setup Instruction</div>
    <!-- <div class="steps">
      <div class="item"><span class="num">1</span><span class="content">Create Onerway account.</span></div>
      <div class="item"><span class="num">2</span><span class="content">Log into your Onerway account.</span></div>
      <div class="item"><span class="num">3</span><span class="content">Click Acquiring > Application > My application to get Store Name & Store ID of that application.</span></div>
    </div> -->
    <div class="form-wrapper">
      <el-form label-position="top" :model="form" :rules="rules" ref="formRef" @submit.native.prevent>
        <el-form-item label="Merchant ID" prop="v3_app_id" ref="effectiveDateRef">
          <el-input
            autocomplete="off"
            v-model="form.v3_app_id"/>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button type="primary" @click="activate">Activate</el-button>
      </div>
    </div>
    <div class="tips">* If you have any problem, please contact with our Support Team: merchant@pacypay.com.</div>
  </div>
</template>
<script>
import { installPaymentAppRh, installPaymentAppLpms } from '@/api/plugin';
export default {
  data() {
    return {
      fullscreenLoading: false,
      form: {
        v3_app_id: '',
      },
      rules: {
        v3_app_id: [{ required: true, message: 'Please enter a valid merchant id', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.$store.dispatch('app/setBrandInfo', {});
  },
  methods: {
    activate() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          const {
            shop, timestamp, hmac, session, host, locale, shopifyAppName, install_from, store_id,
          } = this.$route.query;
          const data = {
            shop,
            timestamp,
            hmac,
            session,
            host,
            locale,
            shopifyAppName,
            install_from,
            store_id,
            v3_app_id: this.form.v3_app_id,
          };
          const method = this.$route.path.indexOf('/payment_app_rh') > -1 ? installPaymentAppRh : installPaymentAppLpms;
          method(data).then((res) => {
            if (res.respCode === '20000') {
              if (res.data && res.data.redictUri) {
                this.$router.push({
                  path: '/v1/plugin/appInstall/shoplazza/success',
                  query: { redirect: window.encodeURIComponent(res.data.redictUri) },
                });
              }
              return;
            }
            this.$router.push({
              path: '/v1/plugin/appInstall/shoplazza/failed',
              query: { m: res.respMsg ? res.respMsg : '' },
            });
            this.fullscreenLoading = false;
          }).catch(() => {
            this.$router.push({
              path: '/v1/plugin/appInstall/shoplazza/failed',
            });
            this.fullscreenLoading = false;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-install {
  width: 760px;
  max-width: 100%;
  padding: 40px 0;
  margin: 0 auto;
  text-align: left;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #27272B;
    line-height: 20px;
    text-align: center;
  }
  .steps {
    padding-top: 20px;
    .item {
      padding: 6px 10px;
      margin-top: 10px;
      background-color: #F2F2F2;
      border-radius: 15px;
      position: relative;
      .num {
        display: inline-block;
        text-align: center;
        width: 20px;
        height: 20px;
        background-color: #fff;
        font-size: 14px;
        font-weight: normal;
        color: #5A6D7C;
        line-height: 20px;
        margin-right: 8px;
        border-radius: 50%;
        position: absolute;
      }
      .content {
        display: inline-block;
        padding-left: 27px;
        line-height: 20px;
        vertical-align: top;
      }
    }
  }
  .form-wrapper {
    margin-top: 30px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #27272B;
      line-height: 20px;
    }
    ::v-deep .el-form {
      .el-form-item {
        margin-bottom: 30px;
      }
      .el-form-item__label {
        font-size: 16px;
        font-weight: 500;
        color: #27272B;
        line-height: 20px;
        padding-bottom: 9px;
        &::before {
          display: none;
        }
      }
    }
    .btns {
      padding-top: 20px;
      button {
        width: 100%;
      }
    }
  }
  .tips {
    font-size: 14px;
    font-family: Avenir-Book, Avenir;
    font-weight: normal;
    color: #27272B;
    line-height: 20px;
    margin-top: 20px;
  }
}
</style>
